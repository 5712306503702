import { createStore } from 'vuex'
export default createStore({
    state: {
        user: { avatar: ""},
        userInfo: {} && window.localStorage.getItem('userInfo'),
        socketStatus: false,
        roomObj: {} && window.localStorage.getItem('roomInfo'),
        registerActive: window.localStorage.getItem('registerActive') ? window.localStorage.getItem('registerActive') : 0,
        liveRoom: null || JSON.parse(window.localStorage.getItem('liveRoom')),
    },
    getters: {
        avatar: state => state.user.avatar,
    },
    mutations: {
        setUserInfo(state, value) {
            window.localStorage.setItem('userInfo', value)
            state.userInfo = value
        },
        setSocketStatus(state, value) {
            state.socketStatus = value
        },
        setroomObj(state, value) {
            window.localStorage.setItem('roomInfo', value)
            state.roomObj = value
        },
        setRegisterActive(state, value) {
            console.log(value,'value')
            window.localStorage.setItem('registerActive', value)
            state.registerActive = value
        },
        setLiveRoom(state, value) {
            window.localStorage.setItem('liveRoom', value)
            state.liveRoom = JSON.parse(value)
        }
    },
    actions: {
    },
    modules: {
    }
})
