import { getToken } from '@/utils/auth'
let client = null
let heartbeatInterval = null
let heartbeat_interval = 5//心跳速率
let reconnect_interval = 3//重连时间
let reconnect_timer = null//重连计时器
let reconnect_count = 10//最大重连次数
let reconnect_current = 1//重连次数
let token = getToken()
const connectSocket = (topic, id) => {
    const wsUrl = `${process.env.VUE_APP_BASE_WS}/wom-websocket/userSocket/${id}`
    if (client) {
        console.log('重连1');
        startHeartbeat()
        client.onmessage = (event) => {
        }
        //断开后的操作
        client.onclose = (e) => {
            clearInterval(heartbeatInterval);//清空心跳
            reconnect_timer = setTimeout(() => {
                clearTimeout(reconnect_timer);
                reconnect_timer = null; // 清空重连定时器
                if (reconnect_current > reconnect_count) {
                    return;
                }
                client=null
                console.log(reconnect_current, "重连次数2", new Date());
                reconnect_current++;
                if (reconnect_current == reconnect_count) {
                    window.location.reload(); // 刷新页面
                }
                connectSocket('1',id);
            }, reconnect_interval * 1000);
        }
        return client
    } else {
        console.log('重连2');
        client = new WebSocket(wsUrl)
        startHeartbeat()
        //断开后的操作
        client.onclose = (e) => {
            clearInterval(heartbeatInterval);//清空心跳
            reconnect_timer = setTimeout(() => {
                clearTimeout(reconnect_timer);
                reconnect_timer = null; // 清空重连定时器
                if (reconnect_current > reconnect_count) {
                    return;
                }
                client=null
                console.log(reconnect_current, "重连次数1", new Date());
                reconnect_current++;
                if (reconnect_current == reconnect_count) {
                    window.location.reload(); // 刷新页面
                }
                connectSocket('1',id);
            }, reconnect_interval * 1000);
        }

    }
    return client
}
// 开始心跳检测
const startHeartbeat = () => {
    if (heartbeatInterval) {
        clearInterval(heartbeatInterval);
    }

    


    heartbeatInterval = setInterval(() => {
        if (client.readyState == '1') {
            let msg = {
                heartToken: token
            }
            client.send(JSON.stringify(msg))
        }
    }, heartbeat_interval * 1000) // 30秒一次心跳包
}
export default connectSocket
