<style lang="scss" scoped>
/* PC端样式 */
@media screen and (min-width: 769px) {
  .moblie {
    display: none;
  }

  .cardLi:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3), 0 24px 38px rgba(0, 0, 0, 0.3),
      0 48px 86px rgba(0, 0, 0, 0.3);
  }

  .cardLi {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    border-radius: 15px;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    background-color: #fff;
    min-height: 300px;
    margin: 0px auto;
    cursor: pointer;

    .word-img {
      position: relative;
      width: 100%;
      img {
        width: 100%;
        // height: 20rem;
        height: 250px;
        object-fit: cover;
      }
      .rowCont {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        display: flex;
        top: 20px;
        padding: 10px;
        width: 100%;
        position: absolute;

        .word-icon {
          position: absolute;
          right: 8px;
          cursor: pointer;

          img {
            width: 50px;
            height: 50px;
            display: block;
          }
        }

        .word-peple {
          position: absolute;
          background-color: #ffecbc;
          font-size: 14px;
          justify-content: center;
          align-items: center;
          border-radius: 51px;
          padding: 10px 16px;
        }
      }

      .message-name {
        font-weight: 800px;
        color: white;
        position: absolute;
        top: 70%;
        font-size: 16px;
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px 25px;
        background: linear-gradient(
          180deg,
          transparent,
          rgba(0, 0, 0, 0.5) 50%
        );
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .word-category {
      font-size: 12px;
      background: #ffffff;
      padding: 20px;
      justify-content: space-between;
      height: 80px;

      .readings {
        padding-left: 10px;
        width: 100%;
        font-size: 20px;
        font-weight: 400;
      }

      .tagCont {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        padding-top: 10px;

        p {
          text-align: center;
          background-color: lightblue;
          padding: 10px;
          border-radius: 20px;
          margin-right: 5px;
          font-size: 12px;
        }
      }

      .reviews {
        font-size: 18px;
      }

      .word-message {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        // margin-bottom: 4px;
        // margin-right: 4px;

        .message-right {
          margin: 5px;
          display: flex;
          align-items: center;
          // width: 50%;

          img {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            margin-right: 10px;
          }

          .message-name {
            cursor: pointer;
            font-size: 12px;
            overflow: hidden;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
        }

        .no-icon {
          margin-right: 6px;
          margin-top: 5px;
          width: 37%;

          p {
            cursor: pointer;
            text-align: center;
            padding: 8px 2px;
            background-color: #0477a5;
            border-radius: 6px;
            color: #ffffff;
            font-size: 14px;
          }
        }

        .message-icon {
          justify-content: space-around;
          display: flex;
          // margin-left: 130px;
          margin-top: 10px;

          p {
            text-align: center;
            width: 40px;
            margin: 4px 0px;
            background-color: rgba(162, 219, 236, 0.5);
            border-radius: 18px;
            padding: 4px 6px;
            margin-right: 6px;
          }

          .p1 {
            cursor: not-allowed;
            background-color: #b6b6b6;
            pointer-events: none;
          }

          .icon1 {
            font-size: 16px;
          }
        }
      }

      .small-type {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-align: left;
        font-weight: 400;
        font-size: 16px;
        cursor: pointer;
        color: #565656;
        // padding: 10px;
      }
    }
  }

  .cardli-end {
    width: 100%;
    .cardli-end-price {
      font-size: 16px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      display: flex;
      padding: 20px;

      p {
        color: grey;
        text-align: right;
        font-weight: 400;
      }
      .fnBtn {
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-right: 10px;
        // padding: 5px;
      }
    }
    .tagCont {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 20px 20px 0px 20px;
      display: flex;
      p {
        text-align: center;
        background-color: #f2f2f2;
        padding: 10px;
        border-radius: 20px;
        margin-right: 5px;
        font-size: 12px;
        color: #565656;
      }
    }

    .fnBtn {
      width: 16px;
      height: 16px;
      object-fit: contain;
      margin-right: 10px;
      // padding: 5px;
    }
    .word-message {
      .no-icon {
        margin-right: 6px;
        margin-top: 5px;
        width: 37%;

        p {
          cursor: pointer;
          text-align: center;
          padding: 8px 2px;
          background-color: #0477a5;
          border-radius: 6px;
          color: #ffffff;
          font-size: 14px;
        }
      }

      .message-icon {
        justify-content: space-around;
        display: flex;
        // margin-left: 130px;
        margin-top: 10px;

        p {
          text-align: center;
          width: 40px;
          margin: 4px 0px;
          background-color: rgba(162, 219, 236, 0.5);
          border-radius: 18px;
          padding: 4px 6px;
          margin-right: 6px;
        }

        .p1 {
          cursor: not-allowed;
          background-color: #b6b6b6;
          pointer-events: none;
        }

        .icon1 {
          font-size: 16px;
        }
      }
    }
  }

  .filtergly {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray !important;
  }

  .life {
    width: 90px;
    position: absolute;
    top: 92%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    font-weight: 700;
    border: 2px solid white;
    border-radius: 20px;
    padding: 5px;
    color: white;
    text-align: center;
    justify-content: center;
  }

  .life-online {
    background-color: rgb(115, 201, 64);
    // font-size: 12px;
  }

  .life-busy {
    background-color: red;
    // font-size: 12px;
  }

  .life-live {
    background-color: #46a6ff;
    // font-size: 12px;
  }

  .line-offline {
    background-color: #b6b6b6;
    // font-size: 12px;
  }
}

//移动端
@media screen and (max-width: 768px) {
  .cardLi {
    margin-top: 10px;
    width: 100%;
    height: 400px;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    border: 2px solid #eee;

    .word-img {
      position: relative;
      width: 100%;

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }

      .rowCont {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        display: flex;
        width: 100%;
        padding: 10px;

        .word-icon {
          position: absolute;
          top: 5px;
          right: 5px;
          z-index: 10;

          img {
            width: 30px;
            height: 30px;
          }
        }

        .word-peple {
          position: absolute;
          background-color: #ffecbc;
          font-size: 14px;
          justify-content: flex-start;
          align-items: center;
          border-radius: 51px;
          padding: 3px 6px;
          top: 10px;
        }
      }
    }

    .word-category {
      font-size: 12px;
      background: #ffffff;
      padding: 10px;
      justify-content: space-between;
      height: 70px;

      .readings {
        width: 100%;
        font-size: 24px;
        font-weight: 800;
      }

      .reviews {
        display: none;
      }

      .word-message {
        .message-right {
          margin: 5px;
          display: flex;
          align-items: center;
        }

        .no-icon {
          margin-right: 6px;
          margin-top: 5px;
          width: 37%;

          p {
            cursor: pointer;
            text-align: center;
            padding: 8px 2px;
            background-color: #0477a5;
            border-radius: 6px;
            color: #ffffff;
            font-size: 14px;
          }
        }

        .message-icon {
          justify-content: space-around;
          display: flex;
          // margin-left: 130px;
          margin-top: 10px;

          p {
            text-align: center;
            width: 40px;
            margin: 4px 0px;
            background-color: rgba(162, 219, 236, 0.5);
            border-radius: 18px;
            padding: 4px 6px;
            margin-right: 6px;
          }

          .p1 {
            cursor: not-allowed;
            background-color: #b6b6b6;
            pointer-events: none;
          }

          .icon1 {
            font-size: 16px;
          }
        }
      }

      .small-type {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-align: left;
        font-weight: 400;
        font-size: 16px;
        cursor: pointer;
        color: #565656;
        // padding: 10px;
      }
    }
  }

  .cardli-end {
    width: 100%;
    .cardli-end-price {
      font-size: 16px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      display: flex;
      padding: 10px;

      p {
        color: grey;
        text-align: right;
        font-weight: 400;
      }
      .fnBtn {
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-right: 10px;
        // padding: 5px;
      }
    }
    .tagCont {
      display: none;
    }

    .fnBtn {
      width: 16px;
      height: 16px;
      object-fit: contain;
      margin-right: 10px;
      // padding: 5px;
    }
    .word-message {
      .no-icon {
        margin-right: 6px;
        margin-top: 5px;
        width: 37%;

        p {
          cursor: pointer;
          text-align: center;
          padding: 8px 2px;
          background-color: #0477a5;
          border-radius: 6px;
          color: #ffffff;
          font-size: 14px;
        }
      }

      .message-icon {
        justify-content: space-around;
        display: flex;
        // margin-left: 130px;
        margin-top: 10px;

        p {
          text-align: center;
          width: 40px;
          margin: 4px 0px;
          background-color: rgba(162, 219, 236, 0.5);
          border-radius: 18px;
          padding: 4px 6px;
          margin-right: 6px;
        }

        .p1 {
          cursor: not-allowed;
          background-color: #b6b6b6;
          pointer-events: none;
        }

        .icon1 {
          font-size: 16px;
        }
      }
    }
  }

  .mobile_call {
    font-size: 12px;
    top: 5px;
    left: 5px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    height: 30px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 2;
    border-radius: 5px;
  }

  .message-name {
    padding-left: 8px;
    width: 100%;
    font-size: 14px;
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }

  .mobile_name {
    position: absolute;
    z-index: 2;
    font-size: 12px;
    font-weight: 600;
    bottom: 8px;
    left: 5px;
    color: white;
    display: flex;
  }

  .text {
    padding: 10px;
    height: 75;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
      sans-serif;
    font-weight: 300;
    line-height: 25px;
    color: #565656 !important;
  }

  .clamp-text {
    /* 设置文本溢出时的样式 */
    display: -webkit-box;
    /* 使用Webkit浏览器的box布局 */
    -webkit-line-clamp: 3;
    /* 控制显示的最大行数 */
    -webkit-box-orient: vertical;
    /* 垂直布局 */
    text-overflow: ellipsis;
    /* 显示省略号 */
    overflow: hidden;
    /* 再次强调隐藏溢出内容，以防万一 */
  }

  .life {
    display: flex;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px;
    font-weight: 700;
    border: 4px solid white;
    border-radius: 50px;
    padding: 5px;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .life-online {
    background-color: rgb(115, 201, 64);
    // font-size: 12px;
  }

  .life-busy {
    background-color: red;
    // font-size: 12px;
  }

  .life-live {
    background-color: #46a6ff;
    // font-size: 12px;
  }

  .line-offline {
    background-color: #b6b6b6;
    // font-size: 12px;
  }

  .small-type {
    display: none;
  }
}
</style>

<template>
  <div class="cardLi" @click="goDetailPage(cardObject.id, cardObject)">
    <div class="word-img">
      <img
        :src="
          cardObject.listImage
            ? ImageUrl + cardObject.listImage
            : require('@/assets/new_performer_bg_experts_1024x768.png')
        "
        alt=""
      />

      <div class="rowCont">
        <div class="word-peple" style="display: flex">
          <svg
            width="21"
            height="19"
            viewBox="0 0 21 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.44484 11.9755L4.28412 17.5429C4.27816 17.7509 4.38038 17.9484 4.55677 18.0708C4.73338 18.193 4.96209 18.2241 5.16806 18.1547L10.6768 16.2887L16.1853 18.1547C16.2575 18.1791 16.3325 18.1911 16.4072 18.1911H16.4116C16.7774 18.1911 17.0739 17.9087 17.0739 17.5602C17.0739 17.5295 17.0717 17.4992 17.0673 17.4695L16.9087 11.9753L20.4739 7.56136C20.6072 7.39648 20.6463 7.17965 20.5789 6.98154C20.5114 6.78364 20.3456 6.63033 20.1363 6.57144L14.5282 4.99667L11.2232 0.402515C11.0996 0.230694 10.8951 0.128275 10.6768 0.128275C10.4585 0.128275 10.254 0.230905 10.1304 0.402515L6.82556 4.99667L1.21727 6.57144C1.00798 6.63033 0.84219 6.78364 0.774636 6.98154C0.707082 7.17944 0.746378 7.39648 0.87972 7.56136L4.44484 11.9755Z"
              fill="#FFB800"
            />
          </svg>

          <span style="margin-left: 8px">
            <b>{{ cardObject.score ? cardObject.score.toFixed(1) : '0.0' }}</b>
          </span>
        </div>

        <div class="word-icon">
          <img
            v-if="cardObject.collect == '0'"
            src="@/assets/fabulous.png"
            @click.stop="changeFollow(cardObject, index)"
          />
          <img
            v-if="cardObject.collect == '1'"
            src="@/assets/aixint.png"
            @click.stop="changeFollowDel(cardObject, index)"
          />
        </div>
      </div>
      <div class="message-name">
        <h4 style="white-space: nowrap">
          {{ cardObject.nickname || 'nickname' }}
        </h4>
      </div>

      <div class="life life-online" v-if="cardObject.status == '1'">ONLINE</div>
      <div class="life life-live" v-else-if="cardObject.status == '2'">
        LIVE
      </div>
      <div class="life life-busy" v-else-if="cardObject.status == '3'">
        BUSY
      </div>
      <div class="life line-offline" v-else-if="cardObject.status == '0'">
        OFFLINE
      </div>
    </div>

    <div class="word-category">
      <div class="small-type">
        {{
          cardObject.aboutMyService
            ? cardObject.aboutMyService
            : 'I’m able to help with any questions in regards to self discovery, spiritual journey and coaching, metaphysics, and many more'
        }}
      </div>
    </div>
    <div class="cardli-end">
      <div class="tagCont">
        <p
          v-for="(item, index) in cardObject.categoryList.slice(0, 3)"
          :key="index"
        >
          {{ item.name }}
        </p>
      </div>
      <div class="cardli-end-price">
        <img
          @click.stop="openShowMessage(cardObject)"
          src="@/assets/chat.png"
          alt=""
          class="fnBtn"
        />
        <img
          @click.stop="callPhone(cardObject)"
          src="@/assets/phone-call.png"
          alt=""
          class="fnBtn"
        />
        <img src="@/assets/video.png" alt="" class="fnBtn" />
        <p>
          from
          <span style="font-weight: bold; color: black"
            >${{ cardObject.perMinute ? cardObject.perMinute : 0 }}/min</span
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { phoneStore } from '../../store/phone1'
import { onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import request from '@/utils/request.js'
import { ElMessage, ElNotification } from 'element-plus'
import { getToken, getType } from '@/utils/auth'
import bus from 'vue3-eventbus'
const store = useStore()
const router = useRouter()
const route = useRoute()
const store1 = phoneStore()
const props = defineProps({
  cardObject: {
    type: Object,
    default: () => {
      return {}
    }
  },
  widthFlage: { type: Boolean, default: () => true }
})
const ImageUrl = process.env.VUE_APP_BASE_ImageUrl
//categoryList
onMounted(() => {})

// 点击进入详情页面
const goDetailPage = (id, item) => {
  router.push('/detailPage/' + id)
  store.commit('setroomObj', JSON.stringify(item))
}

// 添加到收藏
async function changeFollow(item, index) {
  if (!getToken() || getType() == '1') {
    ElMessage({
      message: 'Please login before adding favorites!',
      type: 'warning'
    })
    bus.emit('joinOnLogin', true)
    return
  }
  console.log(store1.onLineList, 'store1.onLineList')
  const userInfo = JSON.parse(store.state.userInfo)
  let data = { userId: userInfo.id, consultantId: item.id }
  const { code } = await request.post('/system/follower/add', data)
  // , duration: 0
  if (code == 200) {
    // console.log(store1.allList,'store1.allList')
    item.collect = '1'
    store1.onLineList.forEach((info) => {
      info.id == item.id && (info.collect = '1')
    })
    store1.allList.forEach((x) => {
      x.id == item.id && (x.collect = '1')
    })
    ElNotification({
      dangerouslyUseHTMLString: true,
      message: `<div>Added to favorites!</div>`,
      position: 'bottom-right'
    })
  }
}
// 删除收藏
async function changeFollowDel(item, index) {
  const userInfo = JSON.parse(store.state.userInfo)
  let data = { userId: userInfo.id, consultantId: item.id }

  const { code } = await request.post('/system/follower/cancel', data)
  if (code == 200) {
    item.collect = '0'
    store1.onLineList.forEach((info) => {
      info.id == item.id && (info.collect = '0')
    })
    store1.allList.forEach((x) => {
      x.id == item.id && (x.collect = '0')
    })
    console.log(store1.onLineList, 'store1.onLineList')
    ElNotification({
      dangerouslyUseHTMLString: true,
      message: `<div>Removed from favorites!</div>`,
      position: 'bottom-right'
    })
  }
}

//打开发送消息弹框
function openShowMessage(item) {
  if (getType() == '1' || !getToken()) {
    console.log(111)
    bus.emit('joinOnLogin', true)
    return
  }
  console.log(item)
  if (window.innerWidth > 768) {
    bus.emit('chatting', true)
    bus.emit('chattingObj', item.nickname)
  } else {
    router.push({
      path: '/msgDetail/' + item.id,
      query: item
    })
  }
}

//打电话
const callPhone = async (item) => {
  console.log(getType())
  if (getType() == '1' || !getToken()) {
    console.log(111)
    bus.emit('joinOnLogin', true)
    return
  }
  console.log(item.id)
  const { code, data } = await request.get(
    '/system/faConsultant/info?consultantId=' + item.id
  )
  if (code == 200) {
    const {
      id,
      listImage,
      nickname,
      liveRoom,
      freeMinutes,
      perMinute,
      status,
      imageDemo
    } = data
    let obj = {
      id,
      listImage,
      nickname,
      liveRoom,
      freeMinutes,
      perMinute,
      status,
      imageDemo
    }
    console.log(obj)
    store1.isPhoneShow = true
    store1.CounselorMessage = obj
  }
}
</script>
