const path = require('path')

function resolve(dir) {
  return path.join(__dirname, dir)
}
// https://cli.vuejs.org/zh/guide/webpack.html#%E7%AE%80%E5%8D%95%E7%9A%84%E9%85%8D%E7%BD%AE%E6%96%B9%E5%BC%8F
module.exports = {
  configureWebpack: {
    resolve: {
      alias: {
        '@': path.resolve(__dirname, './src'),
      },
    },
  },
  publicPath: '/',
  devServer: {
    port: 3000,
    https: false,
    watchOptions: {
      ignored: /node_modules/,
      poll: 1000
    },
    proxy: {
      [process.env.VUE_APP_BASE_API]: {
        // '/api'是代理标识，用于告诉node，url前面是/api的就是使用代理的
        //63:9201
        target: process.env.VUE_APP_BASE_URL, // 目标地址，一般是指后台服务器地址
        changeOrigin: true, // 是否跨域
        pathRewrite: {
          '/api': '' // 重写接口，如果接口本身没有/api，那么此处可以忽略
        },
        // onProxyRes(proxyRes, req, res) {
        //   // 增加代理的真实地址
        //   const realUrl = new URL(
        //     req.url || '',
        //     'http://127.0.0.1:4523/m1/1834146-0-default/' || ''
        //   )
        //   proxyRes.headers['x-real-url1'] = realUrl
        // },
        ws: true, // 是否代理 websockets
        secure: false // 是否https接口
      },
      '/lfy': {
        // target: 'http://192.168.1.63:9201',
        // target: 'http://192.168.1.111:9000',
        target: process.env.VUE_APP_BASE_URL,
        changeOrigin: true,
        pathRewrite: {
          '/lfy': ''
        },
        ws: false,
        secure: false
      },
      '/test': {
        target: 'http://222.71.90.218:8888',
        // ws:'8095',
        changeOrigin: true,// 是否跨域
        pathRewrite: {
          '^/test': ''  // 替换target中的请求地址，也就是说，在请求的时候，url用'/proxy'代替'http://changyou.speeed.cn:8003/'
        }
      },

    }
  },
  chainWebpack(config) {
    // issue：https://coding.imooc.com/learn/questiondetail/280786.html
    config.plugin('define').tap((args) => {
      args[0] = Object.assign(args[0], {
        __VUE_I18N_FULL_INSTALL__: true,
        __VUE_I18N_LEGACY_API__: true,
        __INTLIFY_PROD_DEVTOOLS__: false
      })
      return args
    })

    // 设置 svg-sprite-loader
    // config 为 webpack 配置对象
    // config.module 表示创建一个具名规则，以后用来修改规则
    config.module
      // 规则
      .rule('svg')
      // 忽略
      .exclude.add(resolve('src/icons'))
      // 结束
      .end()
    // config.module 表示创建一个具名规则，以后用来修改规则
    config.module
      // 规则
      .rule('icons')
      // 正则，解析 .svg 格式文件
      .test(/\.svg$/)
      // 解析的文件
      .include.add(resolve('src/icons'))
      // 结束
      .end()
      // 新增了一个解析的loader
      .use('svg-sprite-loader')
      // 具体的loader
      .loader('svg-sprite-loader')
      // loader 的配置
      .options({
        symbolId: 'icon-[name]'
      })
      // 结束
      .end()
    // 创建一个新的规则，用于处理 element-plus 2 的错误
    config.module
      .rule('element-plus-2')
      .test(/\.mjs$/)
      // https://webpack.docschina.org/configuration/module/#ruletype
      .type('javascript/auto')
      .include.add(/node_modules/)
      .end()
  }
}
