// const INotify = (options) => {
//     console.log("JI")
//     function configureINotify(options) {
//         return new iNotify({
//             message: options.message || '有消息了。',
//             effect: options.effect || 'flash',
//             openurl: options.openurl || "http://www.bing.com",
//             onclick: options.onclick || function () {
//                 console.log("---");
//             },
//             audio: options.audio || {
//                 file: ['msg.mp4', 'msg.mp3', 'msg.wav']
//             },
//             interval: options.interval || 1000,
//             updateFavicon: options.updateFavicon || {
//                 textColor: "#fff",
//                 backgroundColor: "#2F9A00"
//             },
//             notification: options.notification || {
//                 title: "通知！",
//                 icon: "",
//                 body: '您来了一条新消息'
//             }
//         });
//     }
//     if (window.Notification.permission == "granted") {
//         // 判断是否有权限
//         const iNotifyInstance = configureINotify(options);
//         iNotifyInstance.show(); // 触发通知
//     } else if (window.Notification.permission != "denied") {
//         window.Notification.requestPermission(function (permission) {
//             // 没有权限发起请求
//             if (permission === "granted") {
//                 const iNotifyInstance = configureINotify(options);
//                 iNotifyInstance.show(); // 触发通知
//             }
//         });
//     }
// }





const INotify = (options) => {

    function sendNotification() {
        console.log("2");
        new Notification(options.title, {
            body: options.message,
            icon: options.icon || "https://pic1.zhuanstatic.com/zhuanzh/50b6ffe4-c7e3-4317-bc59-b2ec4931f325.png",
            openurl: "http://www.bing.com",
            // vibrate: options.vibrate,
            silent: false,
            dir: 'ltr',
            lang: "en",
            // requireInteraction: true,
            // image: "http://touxiang.ikkss.com/nan/List_5.html",
            // badge: "##"
        });
        console.log("3");
    }
    if (window.Notification.permission == "granted") {
        // 判断是否有权限
        sendNotification();
        console.log("4");
    } else if (window.Notification.permission != "denied") {
        window.Notification.requestPermission(function (permission) {
            // 没有权限发起请求
            sendNotification();
        });
        console.log("5");
    }

}

export default INotify
