<template>
  <!-- <template v-if="icon">
    <i v-if="icon.includes('el-icon')" class="sub-el-icon" :class="icon"></i>
    <svg-icon v-else :icon="icon"></svg-icon>
  </template> -->

   <span >{{ $t(`routeName.${title}`) }}</span>
  <el-badge v-if="icon.includes('xiaoxi') && (getType() !== '1') && (getToken())" :value="store.MsgNum.sumUnread? store.MsgNum.sumUnread:''" :max="99"  class="item">
    </el-badge>

</template>

<script setup>
import { phoneStore } from '../../../store/phone1';
import { defineProps, onMounted } from 'vue'
import { getCurrentInstance } from 'vue'
import {getToken , getType} from '@/utils/auth'

const store = phoneStore()
defineProps({
  title: {
    type: String,
    required: true
  },
  icon: {
    type: String,
    required: true
  }
})
const { $t } = getCurrentInstance().proxy

onMounted(()=>{
  // console.log(getType(),'tttttttttttttTTTTTTTT')
})
</script>

<style lang="scss" scoped>

.item{
  // position: absolute;
  // right: 80px;
  top:-15px;
}





</style>
