<template>
  <template v-for="item in typeList" :key="item.id">
    <el-sub-menu v-if="item.categoryList" :index="item.id.toString()">
      <template #title v-if="item.categoryList">
        <span>{{ item.name }}</span>
      </template>
      <el-menu-item
        v-for="i in item.categoryList"
        @click="handleClickTypeList(i)"
        :key="i.id"
        :index="i.id.toString()"
        >{{ i.name }}</el-menu-item
      >
    </el-sub-menu>
    <el-menu-item
      class="menu-out"
      v-else
      @click="handleClickTypeList(i)"
      :index="item.id.toString()"
      >{{ item.name }}</el-menu-item
    >
  </template>
</template>
<script setup name="TypeListItem">
import { defineProps, onMounted, reactive } from "vue";
import { getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import qs from "qs";
defineProps({
  typeList: {
    type: Array,
    default: () => [],
  },
});
const router = useRouter();
const { $t } = getCurrentInstance().proxy;
const da = reactive({
  activeTypeId: "",
});
onMounted(() => {
  getActiveId();
});
const emit = defineEmits(["onMenuItemClicked"]);
const getActiveId = () => {
  let paramStr = window.location.href.split("?")[1];
  let obj = qs.parse(paramStr);
  if (obj.id) {
    da.activeTypeId = Number(obj.id);
  }
};
const handleClickTypeList = (item) => {

  da.activeTypeId = item.id;
  emit("onMenuItemClicked");
  router.push({ path: "/onlineExpert", query: { q: item.name } });
};
</script>
