import 'element-plus/dist/index.css'
import { createApp } from 'vue'
import App from './App.vue'
import installElementPlus from './plugins/element'
import ElementPlus from 'element-plus'
import router from './router'

import store from '@/storeVuex'
import socket from './utils/socket'
/*导入视频播放组件*/
import 'videojs-contrib-hls';
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')

import eventBus from 'vue3-eventbus'
import en from 'element-plus/dist/locale/en.mjs'
// 第一个是videoJs的样式，后一个是vue-video-player的样式，因为考虑到我其他业务组件可能也会用到视频播放，所以就放在了main.js内
import GoogleLogin from 'vue3-google-login'
// 导入全局样式
import './styles/index.scss'
import '@/styles/animate.css'
// 导入文件所需的icons
import installIcons from '@/icons'
import i18n from '@/i18n/index.js'
import VueGtag from 'vue-gtag'

const app = createApp(App)

app.use(GoogleLogin, {
  clientId: '969516104049-j56nhc8ph4c5h6qsgndd58rsrrdm56f5.apps.googleusercontent.com'
})
app.use(eventBus)
app.use(ElementPlus, {
  locale: en,
})
installIcons(app)
installElementPlus(app)
app.use(VideoPlayer)
app.use(router)
app.use(i18n)
app.use(store)
app.provide('socket', socket)
import pinia from './store';
app.use(pinia)
app.use(VueGtag, {
  config: { id: 'G-RM8J16MFZY' }
}, router)
app.mount('#app')
