<template>
  <!-- 支持渲染多级 menu 菜单 -->
  <el-sub-menu v-if="route.children.length > 0" :index="route.path.toString()">
    <template #title>
      <menu-item :title="route.meta.title" :icon="route.meta.icon"></menu-item>
    </template>
    <!-- 循环渲染 -->
    <sidebar-item
      v-for="item in route.children"
      :key="item.path"
      :route="item"
    ></sidebar-item>

  </el-sub-menu>
  <!-- 渲染 item 项 -->
  <el-menu-item v-else :index="route.path.toString()" style="font-weight: 700">
    <menu-item :title="route.meta.title" :icon="route.meta.icon"> </menu-item>
  </el-menu-item>
</template>

<script setup>
import { defineProps } from "vue";
import { getCurrentInstance } from "vue";
import MenuItem from "./MenuItem";
// 定义 props
defineProps({
  route: {
    type: Object,
    required: true,
  },
});
const { $t } = getCurrentInstance().proxy;
</script>

<style lang="scss" scoped>
:deep(.el-menu) {
  border-radius: 0;
  height: 36px;
}
</style>
