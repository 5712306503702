<style lang="scss" scoped>
.message-body {
  width: 800px;
  z-index: 20;

  .message-word {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding: 8px;
    color: #ffffff;
  }

  .message-content {
    border-radius: 10px;
    width: 100%;
    background: #086d68;

    .aside-head {
      .message-search {
        height: 74px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #8f8f8f;
        padding: 16px 0 16px 16px;
        box-sizing: border-box;

        .search-input {
          background-color: #064442 !important;
          border-radius: 6px;

          :deep(.el-input__wrapper) {
            background-color: #064442;
            box-shadow: none !important;
            color: #fff;
          }

          :deep(.el-input__inner) {
            background-color: #064442;
            color: #fff;
          }
        }
      }

      .person-list {
        height: 50vh;
        border-right: 1px solid #8f8f8f;
        border-bottom: 1px solid #8f8f8f;
        padding-top: 10px;
        box-sizing: border-box;
        overflow: auto;

        .person-li-active {
          background-color: #098680;
        }

        .person-li {
          padding: 0 5px;
          height: 42px;
          margin-bottom: 8px;
          color: #e0d8e7;
          display: flex;
          align-items: center;
          cursor: pointer;

          &:hover {
            color: #fff;
          }

          .img-bg {
            width: 38px;
            height: 38px;
            background: linear-gradient(
                217deg,
                rgba(249, 146, 13),
                rgba(252, 177, 40) 70.71%
              ),
              linear-gradient(127deg, rgba(251, 162, 27), rgba(241, 170, 48) 70.71%),
              linear-gradient(336deg, rgba(244, 207, 78), rgba(254, 206, 63) 70.71%);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;

            .live-status {
              position: absolute;
              bottom: 0;
              right: 0;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background-color: #086d68;
              display: flex;
              align-items: center;
              justify-content: center;

              .live-start {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #8fc400;
              }

              .live-end {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #b6b6b6;
              }
            }
          }

          img {
            width: 32px;
            height: 32px;
            display: flex;
            border-radius: 50%;
          }

          .person-name {
            width: 70%;
            overflow: hidden;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            margin-left: 10px;
            word-break: break-all;
          }
        }
      }

      .message-kefu {
        display: flex;
        align-items: center;
        height: 80px;
        box-sizing: border-box;
        padding: 0 5px;
        border-right: 1px solid #8f8f8f;

        .kefu-icon {
          width: 34px;
          height: 34px;
          border-radius: 50%;
          background-color: #ffe400;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;

          img {
            width: 26px;
            height: 26px;
          }
        }

        font-size: 14px;
        color: #ffffff;
      }
    }

    .main {
      padding: 0;

      .main-head {
        height: 74px;
        border-bottom: 1px solid #8f8f8f;
        box-sizing: border-box;

        .main-body {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .head-information {
          box-sizing: border-box;
          padding-left: 20px;
          display: flex;
          align-items: center;
          color: #fff;

          .img-bg {
            width: 38px;
            height: 38px;
            background: linear-gradient(
                217deg,
                rgba(249, 146, 13),
                rgba(252, 177, 40) 70.71%
              ),
              linear-gradient(127deg, rgba(251, 162, 27), rgba(241, 170, 48) 70.71%),
              linear-gradient(336deg, rgba(244, 207, 78), rgba(254, 206, 63) 70.71%);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;

            img {
              width: 32px;
              height: 32px;
              border-radius: 50%;
            }

            .live-status {
              position: absolute;
              bottom: 0;
              right: 0;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background-color: #086d68;
              display: flex;
              align-items: center;
              justify-content: center;

              .live-start {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #8fc400;
              }

              .live-end {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #b6b6b6;
              }
            }
          }

          p {
            margin-left: 5px;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }

      .main-start {
        margin-right: 10px;

        p {
          padding: 6px 10px;
          background-color: #ddb60a;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
        }
      }

      .main-content {
        height: 50vh;
        box-sizing: border-box;
        border-bottom: 1px solid #8f8f8f;

        .main-tip {
          padding-top: 25%;
          color: #fff;
          display: flex;
          flex-direction: column;
          text-align: center;
        }
      }

      .main-footer {
        height: 80px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 15px;

        .footer-input {
          width: 400px;
          height: 48px;

          :deep(.el-textarea__inner) {
            resize: none !important;
            background-color: #064442;
            color: #fff;
          }
        }

        .icon-y {
          font-size: 20px;
          margin-left: 15px;
          cursor: pointer;
        }
      }
    }
  }
}

:deep(.el-textarea__inner:focus) {
  box-shadow: none !important;
}

:deep(.el-textarea__inner) {
  box-shadow: none !important;
}

:deep(.el-input__count) {
  background: transparent !important;
}

.chat-body {
  height: 100%;
  overflow: auto;
  padding: 20px 20px;
  box-sizing: border-box;

  .chat-consultant {
    float: left;
    margin-bottom: 15px;
    color: #fff;
    width: 80%;
    height: auto;
    background-color: #064442;
    border-radius: 10px;
    position: relative;
    margin-top: 5px;

    &::after {
      position: absolute;
      content: "";
      top: 7px;
      box-sizing: border-box;
      left: -19px;
      height: 20px;
      width: 20px;
      border: 10px solid;
      border-color: transparent #064442 transparent transparent;
    }

    .chat-txt-my {
      padding: 10px;
      white-space: pre-wrap;
      word-wrap: break-word;
      text-align: justify;

      .chat-my-img {
        width: 70%;
        height: 70%;
      }
    }
  }

  .chat-in {
    float: right;
    margin-bottom: 25px;
    box-sizing: border-box;
    color: #fff;
    width: 80%;
    height: auto;
    background-color: #064442;
    border-radius: 10px;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      top: 7px;
      box-sizing: border-box;
      right: -19px;
      height: 20px;
      width: 20px;
      border: 10px solid;
      border-color: transparent transparent transparent #064442;
    }

    .chat-txt-my {
      padding: 10px;
      white-space: pre-wrap;
      word-wrap: break-word;
      text-align: justify;

      .chat-my-img {
        width: 70%;
        height: 70%;
      }
    }
  }
}

:deep(.el-upload-list--picture-card) {
  .el-upload-list__item {
    background-color: transparent !important;
    border: none !important;
  }

  .el-upload-list__item-status-label {
    display: none !important;
  }
}

:deep(.el-dialog) {
  background-color: transparent !important;
}
</style>
<template>
  <el-dialog
    class="el-layout"
    v-model="showModel"
    top="5%"
    width="800px"
    @close="closeModelVal"
  >
    <template #header>
      <div class="my-header">
        <div class="message-word">
          <div style="color: #fff">Messenger</div>
        </div>
      </div>
    </template>
    <div class="masked-body">
      <div class="message-body">
        <div class="message-content">
          <el-container>
            <el-aside width="240px" class="aside-head">
              <div class="message-search">
                <el-input
                  class="search-input"
                  v-model="search"
                  placeholder="Please input"
                  clearable
                  type="text"
                />
              </div>
              <ul class="person-list">
                <li
                  :class="
                    item.id == liActiveid ? 'person-li person-li-active' : 'person-li'
                  "
                  v-for="(item, index) in upList"
                  :key="index"
                  @click="changeLiActive(item)"
                >
                  <div class="img-bg">
                    <img
                      :src="
                        item.avatarImage
                          ? ImageUrl + item.avatarImage
                          : require('@/assets/default-user.png')
                      "
                    />
                    <div class="live-status">
                      <div class="live-start" v-if="item.status !== '0'"></div>
                      <div class="live-end" v-else></div>
                    </div>
                  </div>
                  <div class="person-name">{{ item.nickname }}</div>
                  <!-- 信息信息 item.MsgNum-->
                  <el-badge
                    :value="item.msgNum == null || item.msgNum == 0 ? '' : item.msgNum"
                    :max="99"
                    class="item"
                  ></el-badge>
                </li>
                <div
                  v-if="!search.value"
                  style="
                    color: #fff;
                    font-weight: bold;
                    font-size: 14px;
                    padding-left: 5px;
                    margin-bottom: 8px;
                  "
                >
                  Recommended
                </div>
                <div v-if="!search.value">
                  <li
                    :class="
                      item.id == liActiveidTJ ? 'person-li person-li-active' : 'person-li'
                    "
                    v-for="(item, index) in tuijianList"
                    :key="index"
                    @click="changeLiActiveTJ(item)"
                  >
                    <div class="img-bg">
                      <img
                        :src="
                          item.avatarImage
                            ? ImageUrl + item.avatarImage
                            : require('@/assets/default-user.png')
                        "
                      />
                      <div class="live-status">
                        <div class="live-start" v-if="item.status !== '0'"></div>
                        <div class="live-end" v-else></div>
                      </div>
                    </div>
                    <div class="person-name">{{ item.nickname }}</div>
                  </li>
                </div>
                <!-- —————————————————————————————————————————————————————— -->
              </ul>
              <div class="message-kefu">
                <div class="kefu-icon">
                  <img src="@/assets/icon-kefu.png" alt="" />
                </div>
                <span>Start chatting with the expert advisor</span>
              </div>
            </el-aside>
            <!-- —————————————————————————————————————————————————————— -->
            <el-main class="main">
              <div class="main-head">
                <div v-if="liActive || liActiveTJ" class="main-body">
                  <div class="head-information">
                    <div class="img-bg">
                      <img
                        :src="
                          liActive
                            ? liActive.avatarImage
                              ? ImageUrl + liActive.avatarImage
                              : require('@/assets/default-user.png')
                            : liActiveTJ.avatarImage
                            ? ImageUrl + liActiveTJ.avatarImage
                            : require('@/assets/default-user.png')
                        "
                      />
                      <div class="live-status">
                        <div class="live-start" v-if="liActive.status !== '0'"></div>
                        <div class="live-end" v-else></div>
                      </div>
                    </div>
                    <p>{{ liActive ? liActive.nickname : liActiveTJ.nickname }}</p>
                  </div>
                  <div class="main-start" @click="CallClick" v-if="getType() != '1'">
                    <el-button
                      type="primary"
                      :loading="callLoading"
                      style="background-color: yellow; border-color: yellow; color: black"
                    >
                      Call Now</el-button
                    >
                  </div>
                </div>
              </div>
              <div class="main-content">
                <div
                  class="main-tip"
                  v-if="
                    (liActive && messageList.length == 0) ||
                    (liActiveTJ && messageList.length == 0)
                  "
                >
                  <h1>You have no messages from Amenitylntuitive</h1>
                  <h1>Start chatting now!</h1>
                </div>
                <div v-else class="chat-body" ref="msglist">
                  <div v-for="(item, index) in messageList" :key="index">
                    <div
                      style="position: relative"
                      class="chat-in my-message"
                      v-if="item.userType == '1'"
                    >
                      <div
                        style="
                          color: bisque;
                          position: absolute;
                          top: -15px;
                          right: 0px;
                          font-size: 12px;
                        "
                      >
                        {{ ZTime(item.createTime) }}
                      </div>
                      <div class="chat-txt-my" v-if="item.message">
                        {{ item.message }}
                      </div>
                      <div class="chat-txt-my" v-if="item.imgUrl">
                        <ImageUpload
                          :imgUrl="[item.imgUrl]"
                          :disabled="true"
                          :tipTxt="false"
                          :limit="1"
                        />
                      </div>
                    </div>
                    <div
                      style="position: relative"
                      class="chat-consultant my-message"
                      v-if="item.userType == '2'"
                    >
                      <div
                        style="
                          color: bisque;
                          position: absolute;
                          top: -15px;
                          font-size: 12px;
                        "
                      >
                        {{ ZTime(item.createTime) }}
                      </div>
                      <div class="chat-txt-my" v-if="item.message">
                        {{ item.message }}
                      </div>
                      <div class="chat-txt-my" v-if="item.imgUrl">
                        <ImageUpload
                          :imgUrl="[item.imgUrl]"
                          :disabled="true"
                          :tipTxt="false"
                          :limit="1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="main-footer" v-if="liActive || liActiveTJ">
                <el-input
                  class="footer-input"
                  v-model="messageVal"
                  maxlength="250"
                  @keydown.enter="postfaChatsend(1, $event)"
                  placeholder="Please input"
                  show-word-limit
                  type="textarea"
                />
                <!-- <svg-icon class="icon-y" icon="liwu"></svg-icon> -->
                <!-- <svg-icon class="icon-y" icon="tupian" @click="uploadImage"></svg-icon> -->
                &nbsp;&nbsp;&nbsp;&nbsp;
                <el-button color="#ffe933" @click="postfaChatsend(1)">Send</el-button>
                <!-- <svg-icon class="icon-y" icon="huatong"></svg-icon> -->
              </div>
            </el-main>
          </el-container>
        </div>
      </div>
    </div>
    <el-dialog
      v-model="uploadStatus"
      title="Please select an image to upload"
      width="30%"
      :before-close="handleClose"
    >
      <div
        style="width: 100%; display: flex; align-items: center; justify-content: center"
      >
        <ImageUpload
          :imgUrl="messageImgVal"
          :tipTxt="false"
          :limit="1"
          @update:imgUrl="updateimgUrl"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="uploadStatus = false">Cancel</el-button>
          <el-button type="primary" @click="imgConfirm"> Confirm </el-button>
        </span>
      </template>
    </el-dialog>
  </el-dialog>
</template>
<script setup>
import {
  ref,
  defineProps,
  watch,
  inject,
  onMounted,
  nextTick,
  onUnmounted,
  watchEffect,
} from "vue";
import request from "@/utils/request.js";
import { sendWsMsg } from "@/api/messageCall";
import ImageUpload from "@/components/ImageUpload/index.vue";
import { ElMessage } from "element-plus";
import { getType } from "@/utils/auth";
import bus from "vue3-eventbus";
import { phoneStore } from "../../store/phone1";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
const props = defineProps({
  showModelVal: Boolean,
  propNickName: String,
});
const socket = inject("socket");
const messageImgVal = ref("");
const search = ref("");
const messageVal = ref("");
const showModel = ref(false);
const liActive = ref(null);
const liActiveid = ref(null);
const messageList = ref([]);
const msglist = ref("");
const tuijianList = ref([]);
const ImageUrl = process.env.VUE_APP_BASE_ImageUrl;
const liActiveTJ = ref(null);
const liActiveidTJ = ref(null);
const upList = ref([]);
const callTimes = ref(null);
const callLoading = ref(false);
const openid = ref("");
const store = phoneStore();
onMounted(() => {
  bus.on("callLoadingClose", () => {
    callLoading.value = false;
  });
  let id = null;
  bus.on("onMessage", (e) => {
    searchList();
    id = window.localStorage.getItem("gwId");
    if (liActive.value && props.showModelVal) {
      for (let q of upList.value) {
        if (q.id == e.userId && e.userId == id) {
          upList.value = upList.value.map((item) => {
            if (item.id == e.userId) {
              item.msgNum = 0 || null;
            }
            return item;
          });
          getChatInfo(e.userId);
        }
      }
    }
  });
  bus.on("chattingObj", (e) => {
    // 这是点击传来的数据，也就是item
    let obj = {};
    search.value = e;
    setTimeout(() => {
      upList.value.forEach((item) => {
        if (item.nickname == e) {
          obj = item;
        }
      });
      console.log(upList.value, "ipipipipipi");
      changeLiActive(obj);
    }, 1000);
  });
});
onUnmounted(() => {
  clearTimeout(callTimes.value);
});
watchEffect(() => {
  showModel.value = props.showModelVal;
  if (props.showModelVal) {
    searchList();
    getUpList();
  } else {
    search.value = "";
  }
});

watch(
  () => route.query.id,
  (n) => {
    console.log(n, "n..");
  }
);

//时间转化函数
const ProcessingTime = (time) => {
  const date = new Date(time);
  const year = data.getYear();
  const month = date.getMonth() + 1; // 月份从0开始，所以需要加1
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const paddedMinutes = minutes < 10 && minutes > 0 ? `0${minutes}` : minutes;
  const paddedSeconds = seconds < 10 && seconds > 0 ? `0${seconds}` : seconds;
  return `${month}-${day}-${hours}:${paddedMinutes}`;
};

const ZTime = (time) => {
  const diff = new Date().getTimezoneOffset() * 60 * 1000;
  let timestamp = new Date(time).getTime() - diff;
  // 根据毫秒数构建 Date 对象
  let date = new Date(timestamp);
  // 格式化日期
  let dateTime = date.toLocaleString();
  dateTime = dateTime.substring();
  return dateTime;
};

watchEffect(search, () => {
  console.log("333");
  searchList();
});
const emit = defineEmits(["showModelValChange"]);
const CallClick = async () => {
  const { code, data } = await request.get(
    "/system/faConsultant/info?consultantId=" + liActive.value.id
  );
  if (code == "200") {
    const { id, listImage, nickname, liveRoom, freeMinutes, perMinute, status } = data;
    let obj = {
      id,
      listImage,
      nickname,
      liveRoom,
      freeMinutes,
      perMinute,
      status,
    };
    store.CounselorMessage = obj;
    store.isPhoneShow = true;
  }
};
function closeModelVal() {
  clearTimeout(callTimes.value);
  emit("showModelValChange", false);
  liActiveid.value = null;
  messageList.value = [];
  liActive.value = null;
}

function changeLiActive(item) {
  upList.value = upList.value.map((info) => {
    if (info.id == item.id) {
      info.msgNum = null;
    }
    return info;
  });
  liActive.value = item;
  liActiveid.value = item.id;
  liActiveTJ.value = null;
  liActiveidTJ.value = null;
  if (item.id) {
    console.log("1111");
    getChatInfo(item.id);
  }
}

function changeLiActiveTJ(item) {
  liActive.value = item;
  liActiveid.value = null;
  liActiveTJ.value = item;
  liActiveidTJ.value = item.id;
  if (item.id) {
    console.log("2222");
    getChatInfo(item.id);
  }
}
async function getUpList() {
  // /* &isRecommend=true */&hasChat=true
  const { code, rows } = await request.get(
    "/system/faConsultant/list?pageSize=10000&isRecommend=2"
  );
  if (code == 200) {
    tuijianList.value = rows.slice(0, 10);
  }
}
async function searchList() {
  const { code, rows } = await request.get(
    `/system/faConsultant/list?pageSize=10000${
      search.value ? "&nickname=" + search.value : "&hasChat=true"
    }`
  );
  if (code == 200) {
    store.gwMsgList = rows;
    store.changeMsgList();
    upList.value = store.gwMsgList;
  }
  // console.log(store.gwMsgList, 'store.gwMsgList')
}
async function getChatInfo(targetId) {
  window.localStorage.setItem("gwId", targetId);

  console.log(targetId, "用户-----idididi");

  const { code, data } = await request.get(
    "/system/faChat/info?pageSize=10000000&targetId=" + targetId
  );
  if (code == 200) {
    if (data) {
      // data.infos.forEach(item => {
      //     item.userType = '2'
      // })
      messageList.value = data.infos;
    } else {
      messageList.value = [];
    }

    nextTick(() => {
      msgScroll();
    });

    // console.log('messageList', messageList.value)
  } else {
    messageList.value = [];
    ElMessage("Server error.");
  }
}
async function postfaChatsend(type, e) {
  if (e) {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  }
  let data;
  if (type == 1) {
    if (!messageVal.value) {
      ElMessage("Input cannot be empty.");
      return;
    }
    data = {
      targetId: liActiveid.value || liActiveidTJ.value,
      message: messageVal.value,
    };
  }
  if (type == 2) {
    if (!messageImgVal.value) {
      ElMessage("Please select an image to upload.");
      return;
    }
    data = {
      targetId: liActiveid.value || liActiveidTJ.value,
      imgUrl: messageImgVal.value,
    };
  }
  const { code } = await request.post("/system/faChat/send", data);
  if (code == 200) {
    if (type == 1) {
      messageVal.value = "";
    }
    if (type == 2) {
      messageImgVal.value = null;
      uploadStatus.value = false;
    }
    console.log("?");
    getChatInfo(liActiveid.value || liActiveidTJ.value);
    // getChatInfo(liActiveid.value)
  } else {
    ElMessage("Server error.");
  }
}
function msgScroll() {
  if (msglist.value && msglist.value.scrollHeight) {
    msglist.value.scrollTop = msglist.value.scrollHeight;
  }
}
const uploadStatus = ref(false);
function handleClose() {
  uploadStatus.value = false;
}
function uploadImage() {
  uploadStatus.value = true;
  console.log("上传图片");
}
function updateimgUrl(e) {
  messageImgVal.value = e;
}
function imgConfirm() {
  postfaChatsend(2);
}
</script>
