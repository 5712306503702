<style lang="scss" scoped>
/* PC端样式 */
@media screen and (min-width: 769px) {
  .footer {
    padding-top: 20px;
    // background: #02758a;
    // width: 100%;
    color: #ffff;
    padding: 0 10rem;
    color: #000000;
    margin: 30px 0;
    text-align: center;

    .end {
      span {
        margin: 10px;
      }
    }

    .introduce {
      color: #8f63ec;
      font-size: 14px;
      margin-bottom: 10px;
    }

    .title {
      font-size: 14px;
      font-weight: 500;
      color: #b1b1b1;
      margin: 9px 0;
    }

    .top-img {
      width: 75px;
      height: 75px;
      margin: auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .desc {
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 12px;
      font-family: "Open Sans", Arial, sans-serif;
      color: darkgray;
    }
  }
}

/* 移动端样式 */
@media screen and (max-width: 768px) {
  .footer {
    margin: 50px 20px;
    text-align: center;

    .end {
      span {
        margin: 10px;
      }
    }

    .introduce {
      color: #8f63ec;
      width: 100%;
      font-size: 12px;
      margin-bottom: 10px;
    }

    .title {
      font-size: 12px;
      font-weight: 500;
      color: #b1b1b1;
      margin: 9px 0;
    }

    .top-img {
      width: 75px;
      height: 75px;
      margin: auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .desc {
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 10px;
      font-family: "Open Sans", Arial, sans-serif;
      color: darkgray;
    }
  }

  .mobile {
    display: none;
  }
}
</style>

<template>
  <div class="footer">
    <!-- <div class="top"> -->
    <div class="top-img">
      <img src="@/assets/footerImg.png" alt="" />
    </div>

    <div class="desc">
      Zest Wisdom provides a platform where individuals can access answers,
      advice, and services; however, it does not assess or assure the accuracy
      or effectiveness of these offerings. The Site and Zest Wisdom's services
      are provided on an "as is" basis, without any warranties.<br /><br />
      Regarding the quality and authenticity of information provided by experts
      on the site, Zest Wisdom disclaims responsibility. Utilizing the site and
      its services implies your acceptance of the Disclaimer's terms and
      conditions. You acknowledge that any reliance on answers, advice, or
      services is undertaken at your own risk, and Zest Wisdom is not liable for
      any resulting damages or losses. When you submit a question, you
      explicitly understand and agree that Zest Wisdom is not accountable for
      the responses you do or do not receive. You further agree to hold Zest
      Wisdom harmless from any potential loss, injury, harm, or damage arising
      from your use of the service or your reliance on any provided response.
    </div>
    <!-- </div> -->

    <div class="title">
      © 2024 zestwisdom.com - All Rights Reserved! -v5.3.2(m3)
    </div>

    <div class="introduce">
      <a style="cursor: pointer" @click="goPageQuestion">F.A.Q</a> &nbsp;
      <a style="cursor: pointer; margin-left: 5px" @click="goPageTrem">Terms & Conditions</a>
      <a style="cursor: pointer; margin-left: 15px" href="mailto:support@zestwisdom.com">Contact Us</a>
    </div>

    <div class="end">
      <a href="https://www.facebook.com/zestwisdom" target="_blank">
        <span>
          <svg
            width="46"
            height="47"
            viewBox="0 0 46 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M46 23.8267C46 11.1241 35.7026 0.82666 23 0.82666C10.2974 0.82666 0 11.1241 0 23.8267C0 35.3064 8.41072 44.8218 19.4062 46.5472V30.4751H16.8906C15.0547 30.4751 13.5664 28.9868 13.5664 27.1509C13.5664 25.315 15.0547 23.8267 16.8906 23.8267H19.4062V18.7595C19.4062 12.9951 22.8401 9.81103 28.0937 9.81103C28.979 9.81103 29.8786 9.86663 30.6729 9.93871C32.1629 10.0739 33.2422 11.3544 33.2422 12.8505V13.0203C33.2422 14.622 31.9437 15.9204 30.342 15.9204C27.485 15.9204 26.5938 17.6935 26.5938 19.5124V23.8267H29.0997C31.1365 23.8267 32.6943 25.6416 32.3855 27.6548C32.1367 29.2772 30.741 30.4751 29.0997 30.4751H26.5938V46.5472C37.5893 44.8218 46 35.3064 46 23.8267Z"
              fill="#C1C1C1"
            />
            <path
              d="M31.9529 30.4751L32.9727 23.8267H26.5938V19.5124C26.5938 17.6935 27.485 15.9204 30.342 15.9204H33.2422V10.2603C33.2422 10.2603 30.6102 9.81104 28.0937 9.81104C22.8401 9.81104 19.4062 12.9951 19.4062 18.7595V23.8267H13.5664V30.4751H19.4062V46.5472C20.5774 46.731 21.7772 46.8267 23 46.8267C24.2228 46.8267 25.4226 46.731 26.5938 46.5472V30.4751H31.9529Z"
              fill="white"
            />
          </svg>
        </span>
      </a>

      <a href="https://www.instagram.com/zestwisdomsocial" target="_blank">
        <span>
          <svg
            width="46"
            height="47"
            viewBox="0 0 46 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23 46.8267C35.7025 46.8267 46 36.5292 46 23.8267C46 11.1241 35.7025 0.82666 23 0.82666C10.2975 0.82666 0 11.1241 0 23.8267C0 36.5292 10.2975 46.8267 23 46.8267ZM17 9.82666C12.5817 9.82666 9 13.4084 9 17.8267V29.8267C9 34.2449 12.5817 37.8267 17 37.8267H29C33.4183 37.8267 37 34.2449 37 29.8267V17.8267C37 13.4084 33.4183 9.82666 29 9.82666H17ZM29 23.8267C29 27.1404 26.3137 29.8267 23 29.8267C19.6863 29.8267 17 27.1404 17 23.8267C17 20.513 19.6863 17.8267 23 17.8267C26.3137 17.8267 29 20.513 29 23.8267ZM30.5 16.8267C31.3284 16.8267 32 16.1551 32 15.3267C32 14.4982 31.3284 13.8267 30.5 13.8267C29.6716 13.8267 29 14.4982 29 15.3267C29 16.1551 29.6716 16.8267 30.5 16.8267Z"
              fill="#C4C4C4"
            />
          </svg>
        </span>
      </a>

      <a href="https://www.tiktok.com/@zestwisdom" target="_blank">
        <img
          src="@/assets/tiktok.png"
          width="46"
          height="47"
          style="margin-left: 10px"
        />
      </a>

      <a href="https://t.me/ZestWisdom1" target="_blank">
        <img
          src="@/assets/telegram.png"
          width="46"
          height="47"
          style="margin-left: 10px"
        />
      </a>
    </div>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router'
import { getToken, getType } from '@/utils/auth'
import { ElMessage, ElLoading, ElMessageBox } from 'element-plus'

const router = useRouter()
const route = useRoute()
const goPageQuestion = () => {
  if (route.path == '/callPlayer/receive') {
    ElMessageBox.alert(
      'Hang up the phone before you leave this page!',
      'Warm and cozy',
      {
        confirmButtonText: 'OK'
      }
    )
    return
  }
  router.push('/pageQuestion')
}
const goPageTrem = () => {
  if (route.path == '/callPlayer/receive') {
    ElMessageBox.alert(
      'Hang up the phone before you leave this page!',
      'Warm and cozy',
      {
        confirmButtonText: 'OK'
      }
    )
    return
  }
  router.push('/pageTerm')
}
const goPageLogin = () => {
  if (getToken() && getType()) {
    ElMessage({
      message: 'Your account is logged in.',
      type: 'warning'
    })
  } else {
    router.push('/login')
  }
}
const goPageReg = () => {
  if (getToken() && getType()) {
    ElMessage({
      message: 'Your account is logged in.',
      type: 'warning'
    })
  } else {
    router.push('/pageReg')
  }
}
</script>
